import { useContext, useEffect, useState } from 'react';
import { ComingSoon } from '../ComingSoon/ComingSoon';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import styles from './Events.module.scss';
import { api } from '@lib/api';
import { ResidentContext } from '../../../context/ResidentContext';
import { CircularProgress, Tooltip } from '@mui/material';
import { Input } from '@ui/Input/Input';
import {
  CardGiftcard,
  CreditCard,
  FilterList,
  Search,
  Sort,
} from '@mui/icons-material';
import { Dropdown } from '@ui/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { dateRange } from '@util/datetime';

export const Events = ({ isEmpty }) => {
  const { data } = useContext(ResidentContext);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [payment, setPayment] = useState('all');
  const [sort, setSort] = useState('new');
  const [isLoading, setIsLoading] = useState(true);
  const { community } = useSelector(state => state.community);
  const [registrations, setRegistrations] = useState([]);

  const PAYMENT_TITLES = {
    all: 'Оплата',
    free: 'Бесплатные',
    paid: 'Оплачено',
    unpaid: 'Не оплачено',
  };

  const fetchEvents = async () => {
    if (isEmpty) return;
    setIsLoading(true);
    const response = await api.get(`/calendar/registrations/user/${data.userId}?communityId=${community.id}`, {
      params: {
        limit: 100,
        page: 1
      },
    });
    setRegistrations(response.data.data);
    updateEvents(sortRegistrations(response.data.data));
    setIsLoading(false);
  };

  const paymentChanged = (newValue) => {
    setPayment(newValue);
  };

  const icons = {
    free: (
      <Tooltip title="Бесплатное мероприятие">
        <CardGiftcard />
      </Tooltip>
    ),
    unpaid: (
      <Tooltip title="Не оплачено">
        <CreditCard />
      </Tooltip>
    ),
    paid: (
      <Tooltip title="Оплачено">
        <CreditCard />
      </Tooltip>
    ),
  };

  const filterEvents = () => {
    if (payment === 'all') return events;
    return events.filter((event) => event.payment === payment);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const filteredEvents = filterEvents();

  const updateEvents = (items) => {
    setEvents(items.map(item => {
      return {
        id: item.event.id,
        start: item.event.start,
        title: item.event.title,
        dates: dateRange(item.event.start, item.event.finish),
        comment: item.event.comment,
        payment: item.event.price ? item.paymentStatus : 'free',
      };
    }));
  };

  const sortRegistrations = (regs) => {
    let items = [...(regs || registrations)];
    if (search && search.trim()) {
      items = items.filter(item => item.event.title.toLowerCase().includes(search.trim().toLowerCase()));
    }
    if (payment === 'free') {
      items = items.filter(item => !item.event.price);
    } else if (payment === 'paid') {
      items = items.filter(item => item.paymentStatus === 'paid');
    } else if (payment === 'unpaid') {
      items = items.filter(item => item.paymentStatus === 'unpaid');
    }
    if (sort === 'new') {
      items = items.sort((a, b) => new Date(b.event.start) - new Date(a.event.start));
    } else if (sort === 'old') {
      items = items.sort((a, b) => new Date(a.event.start) - new Date(b.event.start));
    }
    console.log('ITEMS', { items, search, payment, sort });
    return items;
  };

  useEffect(() => {
    const sortedItems = sortRegistrations();
    updateEvents(sortedItems);
  }, [search, payment, sort]);

  return (
    <div className={styles.events}>
      <SectionHeader
        title="Мероприятия"
        description="Статистика посещенных мероприятий сообщества"
      />
      {isEmpty ? (
        <ComingSoon />
      ) : (
        <>
          <div className={styles.search}>
            <Input
              placeholder="Поиск"
              icon={Search}
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              style={{ width: '200px' }}
            />
          </div>
          <div className={styles.filters}>
            <div className={styles.payment}>
              <Dropdown
                title={payment === 'all' ? 'Оплата' : PAYMENT_TITLES[payment]}
                value={payment}
                onChange={paymentChanged}
                icon={CreditCard}
                options={[
                  { title: 'Все', value: 'all' },
                  { title: 'Бесплатные', value: 'free' },
                  { title: 'Оплачено', value: 'paid' },
                  { title: 'Не оплачено', value: 'unpaid' },
                ]}
              />
            </div>
            <div className={styles.sort}>
              <Dropdown
                icon={FilterList}
                title={sort === 'new' ? 'Сначала новые' : 'Сначала старые'}
                value={sort}
                onChange={setSort}
                options={[
                  { title: 'Сначала новые', value: 'new' },
                  { title: 'Сначала старые', value: 'old' },
                ]}
              />
            </div>
          </div>
          <div className={styles.list}>
            {/* {isLoading && <CircularProgress />} */}
            {filteredEvents.map((event) => (
              <div className={styles.event} key={event.id}>
                <div className={styles.header}>
                  <div className={styles.title}>{event.title}</div>
                  <div className={styles.dates}>{event.dates}</div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.comment}>
                    Комментарий: {event.comment || 'Нет'}
                  </div>
                  <div className={styles.icon} data-status={event.payment}>
                    {icons[event.payment]}
                  </div>
                </div>
              </div>
            ))}
            {!isLoading && !filteredEvents.length && (
              <div className={styles.empty}>
                Ни одного мероприятия не найдено.
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
